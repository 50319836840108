<template>
      <b-col>
        <b-row>
          <b-col md="6">
            <b-form-textarea
                id="textarea"
                v-model="textAnswer"
                placeholder="Напишите ответ на отзыв или выберете из готовых шаблонов..."
                rows="3"
                max-rows="6"
                style="font-size: 12px"
            ></b-form-textarea>

          </b-col>
          <b-col md="6">
            <b-form-select v-model="textAnswer" :options="options" :select-size="8" style="font-size: 12px"></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-top: 15px">
          <b-col>
            <b-button block variant="primary" :disabled="isLoadingBtn" @click="sendFeedback()"><b-spinner small v-if="isLoadingBtn"></b-spinner> Отправить ответ {{id}}</b-button>
           </b-col>
        </b-row>
      </b-col>

</template>

<style scoped>
.form-control {
  font-size: 12px;
}
.b-rating.disabled, .b-rating:disabled {
  background-color:transparent;
  color: #6c757d;
}
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "ozon-feedbacks-row-answer",
  props: ["id","uuid","company_id"],
  components: { },
  data() {
    return {
      textAnswer:null,
      isLoadingBtn:false,
      options: [
        { value: 'a', text: 'Шаблон 1' },
        { value: 'b', text: 'Шаблон 2' },
        { value: 'c', text: 'Шаблон 3' },
        { value: 'e', text: 'Шаблон 4' },
        { value: 'f', text: 'Шаблон 5' }
      ],
      isLoading: true,

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    // console.log(this.templates)
    // this.options=this.templates.map(e=>({value:e.text,text:`${e.name}`}))

    // this.getReport()
  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    sendFeedback() {
this.isLoadingBtn=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wb-feedbacks/sendFeedbackOzon`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({id:this.id, text:this.textAnswer, company_id:this.company_id, uuid:this.uuid})
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(resp)
            if (!resp.error) {
              this.alertMsg('Отправить отзыв | Успешно', `ok`, 'success')
              this.$emit('sendok',this.id)
            }
            if (resp.error) this.alertMsg('Отправить отзыв | Ошибка', `Ошибка ozon, ${resp.errorText}`, 'danger')
            this.isLoadingBtn=false
          })
          .catch(err => {
            this.alertMsg('Отправить отзыв | Ошибка', `Ошибка сервера, попробуйте ещё раз ${err}`, 'danger')
            this.isLoadingBtn=false
          })
    }

  },
  computed: {

  },
};
</script>