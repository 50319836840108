<template>
  <b-row>
    <b-col>
      <b-row  align-h="end">
        <b-col  lg="8" xl="6" class="ml-md-auto p-3">
          <b-form-checkbox
              id="checkbox-1"
              v-model="filters.withText"
              name="checkbox-1"
              switch inline
          >
            Только с текстом
          </b-form-checkbox>

          <b-form-checkbox
              id="checkbox-2"
              v-model="filters.withPhoto"
              name="checkbox-2" switch inline
          >
            Только с фото
          </b-form-checkbox>

          <b-form-checkbox
              id="checkbox-3"
              v-model="filters.onlyQ"
              name="checkbox-3" switch inline
          >
            Только вопросы
          </b-form-checkbox>

<!--          <b-form-group label="Button style radios" v-slot="{ ariaDescribedby }">-->
            <b-form-radio-group
                id="btn-radios-1"
                v-model="filters.mp"
                :options="[{text:'Все',value:''},{text:'Wb',value:'Wb'},{text:'Ozon',value:'Ozon'}]"

                name="radios-btn-default"
                size="sm"
                buttons
            ></b-form-radio-group>
<!--          </b-form-group>-->

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
        <b-col>Всего неотвеченных отзывов: {{feedbacksList.length}}</b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="feedbacksListFiltered"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              stacked="md"
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <!--          <template #thead-top="">-->
            <!--            <b-tr>-->
            <!--              <b-th colspan="5">Всего с прибылью меньше 50руб. {{reportLog.length}} артикулов</b-th>-->
            <!--              <b-th></b-th>-->
            <!--              <b-th colspan="4" class="text-center">Цена закупочная</b-th>-->
            <!--              <b-th></b-th>-->
            <!--              <b-th colspan="5" class="text-center">Цена на WB</b-th>-->
            <!--              <b-th></b-th>-->
            <!--            </b-tr>-->
            <!--          </template>-->
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

            <template #cell(createdDate)="{item}">
              {{dayjs(item.createdDate).format("DD.MM.YYYY HH:mm:ss")}}
            </template>
            <template #cell(feedback)="{item}">
              <template v-if="item.productDetails.mp=='Ozon'">
                <a :href="item.productDetails.url" target="_blank"><b>{{item.productDetails.productName}}</b>
                  <span> | ozon {{item.productDetails.supplierName}}</span>
                 </a><br>
                {{item.text || 'без текста'}} <br>
                <template v-if="item.media.videos.length>0">
                  <br>
                  <a target="_blank" :href="mini" v-for="(mini,indx) of item.media.videos"  alt="" :key="`videos-ozon-${item.productDetails.uuid}-${indx}`">
<!--                    <b-img style="max-width: 100px" thumbnail fluid left :src="mini" ></b-img>-->
                    видео {{indx+1}}
                  </a>
                  </template>

                <template v-if="item.media.photos.length>0">
                  <a target="_blank" :href="mini" v-for="(mini,indx) of item.media.photos"  alt="" :key="`photo-ozon-${item.productDetails.uuid}-${indx}`">
                    <b-img style="max-width: 100px" thumbnail fluid left :src="mini" ></b-img>
                  </a>
                </template>

              </template>
              <template v-else>
                <a :href="`https://www.wildberries.ru/catalog/${item.productDetails.nmId}/detail.aspx`" target="_blank"><b>{{item.productDetails.productName}}</b>
                  <span v-if="item.productDetails.supplierName=='Берлов Константин Игоревич ИП'"> | wb ИП</span>
                  <span v-if='item.productDetails.supplierName.indexOf("НЕОВИЖН")+1'> | wb НЕО</span>
                  <span v-if="item.productDetails.supplierName.indexOf('ВИП')+1"> | wb ВИП</span>
                  <span v-if="item.productDetails.supplierName=='Индивидуальный предприниматель Дегтярев Юрий Викторович'"> | wb Дегтярёв</span>
                </a><br>
                {{item.text || 'без текста'}} <br>
                <b-img thumbnail fluid left v-for="(mini,indx) of item.photoLinks" :src="mini.miniSize" alt="" :key="`photo-${item.productDetails.nmId}-${indx}`"></b-img>
                <!--              <b-img thumbnail fluid left src="https://feedback03.wb.ru/vol1154/part115491/115491476/photos/ms.jpg" alt=""></b-img>-->
                <!--              <b-img thumbnail fluid left src="https://feedback03.wb.ru/vol1154/part115491/115491476/photos/ms.jpg" alt=""></b-img>-->

              </template>


            </template>

            <template #cell(productValuation)="{item}">
              <b-form-rating v-if="item.productValuation>0" disabled inline no-border v-model="item.productValuation" ></b-form-rating>
              <span v-else>вопрос</span>
            </template>
            <template #cell(answer)="row">
              <b-button variant="outline-primary" size="sm" style="font-size: 12px" @click="row.toggleDetails"><b-icon-list-ol style="width: 15px; height: 15px"/>{{ row.detailsShowing ? 'Свернуть' : 'Ответить' }}</b-button>
            </template>
            <template #row-details="{item}">
              <template v-if="item.productDetails.mp=='Ozon'">
                <ozon-feedbacks-row-answer @sendok="hideRow($event)" :id="item.id" :company_id="item.productDetails.company_id" :uuid="item.productDetails.uuid" />

              </template>
              <template v-else>
                <template v-if="item.productValuation>0">
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName=='Берлов Константин Игоревич ИП'" :templates="wbTemplates.filter(e=>e.seller=='IP' && e.type==1)" :id="item.id" :seller="'ip'" :type="'feedbacks'" />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if='item.productDetails.supplierName.indexOf("НЕОВИЖН")+1' :templates="wbTemplates.filter(e=>e.seller=='NEO' && e.type==1)" :id="item.id" :seller="'ooo'" :type="'feedbacks'" />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName.indexOf('ВИП')+1" :templates="wbTemplates.filter(e=>e.seller=='VIP' && e.type==1)" :id="item.id" :seller="'vipl'" :type="'feedbacks'" />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName=='Индивидуальный предприниматель Дегтярев Юрий Викторович'" :templates="wbTemplates.filter(e=>e.seller=='DEGT' && e.type==1)" :id="item.id" :seller="'degt'" :type="'feedbacks'" />
                </template>
                <template v-else>
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName=='Берлов Константин Игоревич ИП'" :templates="wbTemplates.filter(e=>e.seller=='IP' && e.type==2)" :id="item.id" :seller="'ip'" :type="'questions'"  />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if='item.productDetails.supplierName.indexOf("НЕОВИЖН")+1' :templates="wbTemplates.filter(e=>e.seller=='NEO' && e.type==2)" :id="item.id" :seller="'ooo'" :type="'questions'" />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName.indexOf('ВИП')+1" :templates="wbTemplates.filter(e=>e.seller=='VIP' && e.type==2)" :id="item.id" :seller="'vipl'" :type="'questions'" />
                  <wb-feedbacks-row-answer @sendok="hideRow($event)" v-if="item.productDetails.supplierName=='Индивидуальный предприниматель Дегтярев Юрий Викторович'" :templates="wbTemplates.filter(e=>e.seller=='DEGT' && e.type==2)" :id="item.id" :seller="'degt'" :type="'feedbacks'" />
                </template>
              </template>



              </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>

    </b-col>


  </b-row>

</template>

<style scoped>
.b-rating.disabled, .b-rating:disabled {
  background-color:transparent;
  color: #6c757d;
}
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
import WbFeedbacksRowAnswer from "@/WbFeedbacks/WbFeedbacksRowAnswer.vue";
import dayjs from "dayjs";
import OzonFeedbacksRowAnswer from "@/WbFeedbacks/OzonFeedbacksRowAnswer.vue";

// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "wb-feedbacks",
  components: {OzonFeedbacksRowAnswer, WbFeedbacksRowAnswer},
  data() {
    return {
      feedbacksList: [],
      fields: [
        { key: 'createdDate', label: 'Дата создания отзыва', sortable: true, sortDirection: 'desc' },
        { key: 'feedback', label: 'Отзыв', sortable: false, sortDirection: 'desc' },
        { key: 'productValuation', label: 'Оценка', sortable: true, sortDirection: 'desc' },
        { key: 'answer', label: '', sortable: false, sortDirection: 'desc' }
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 200,
      perPage: 100,
      filters: {
        withText: false,
        withPhoto: false,
        onlyQ:false,
        mp: '' // Ozon, Wb
      },
      wbTemplates:[]

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    await Promise.all([this.getReport(),this.getTemplates()])


  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    hideRow(id) {
      // console.log('hide row')
      // console.log(id)
      this.alertMsg('Отправить ответ | Успешно', `ok ${id}`, 'success')
      this.feedbacksList = this.feedbacksList.filter(e=>e.id!=id)
    },
    getReport() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wb-feedbacks/getNewReview`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.feedbacksList = resp.items
              this.isLoading=false
              this.totalRows=resp.items.length
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    getTemplates() {
      // this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wb-feedbacks/getTemplateReview`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            // console.log(resp)
            if (resp.status) {
              this.wbTemplates = resp.items
              // this.totalRows=resp.items.length
              // this.isLoading=false
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки шаблонов', `Ошибка сервера, попробуйте обновить страницу`, 'danger')


          })
    }

  },
  computed: {
    dayjs() {
      return dayjs
    },
    feedbacksListFiltered: function () {
      let result = this.feedbacksList //.slice(0,5)

      if (this.filters.withPhoto) {
        result = [...result.filter(e=>e.productDetails.mp!='Ozon').filter(e=>e.photoLinks),
            ...result.filter(e=>e.productDetails.mp=='Ozon').filter(e=>e.media.photos.length>0 || e.media.videos.length>0)]
      }
      if (this.filters.withText) result = result.filter(e=>e.text)
      if (this.filters.onlyQ) result = result.filter(e=>e.productValuation==0)
      if(this.filters.mp) result = result.filter(e=>e.productDetails.mp==this.filters.mp)


      return result
    },
  },
  watch:{
    'feedbacksListFiltered': function () {
      this.totalRows=this.feedbacksListFiltered.length
    }
  }
};
</script>